import React, { useEffect, useState } from 'react';
import { getTableByIdBd, getVariables, validarMobil } from '../../config/functions';
import { useHistory } from "react-router-dom";
import { Sidebar, Menu as ProMenu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderES } from '../LoaderES';
import { obtenerURLImagen } from '../../config/firebase';
import { Plantillas } from '../administracion/plantillas/Plantillas';
import LogoutButton from './LogoutButton';
import moment from 'moment';
import Home from '../Home';
import LiveData from '../LiveData';
import LiveGraphs from '../graficas/LiveGraphs';
import Pits from '../Pits';
import MyRigs from '../MyRigs';
import Variables from '../administracion/variables/Variables';
import Users from '../administracion/usuarios/Users';
import VariablesForm from '../administracion/formulacion/VariablesForm';
import Help from '../ayuda/Help';

import './css/menu.css';
import 'react-toastify/dist/ReactToastify.css';
import Rigs from '../administracion/rigs/Rigs';
import { Config } from './Config';

const Menu = () => {


    const [colors, setColors] = useState({});

    const [logo, setLogo] = useState('')
    const [logoW, setLogoW] = useState('')
    const [logoTw, setLogoTw] = useState('')
    const [logoTwW, setLogoTwW] = useState('')

    const history = useHistory();

    const user = JSON.parse(localStorage.getItem("token")).userName;
    const tipo = JSON.parse(localStorage.getItem("token")).tipo;
    const uId = JSON.parse(localStorage.getItem("token")).id;

    const [mobile, setMobile] = useState(true)
    const [horizontal, setHorizontal] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const themeFromLocalStorage = localStorage.getItem("theme");
    const [theme, setTheme] = useState(themeFromLocalStorage !== null ? themeFromLocalStorage : 'light');
    const [isLoading, setIsLoading] = useState(false)

    const [adminShow, setAdminShow] = useState(false);
    const [reloj, setReloj] = useState(moment().format('LTS'))

    const [variables, setVariables] = useState([])

    const rigFromLocalStorage = JSON.parse(localStorage.getItem("rig"));
    const [rigs, setRigs] = useState([])
    const [rig, setRig] = useState(rigFromLocalStorage === null || rigFromLocalStorage === '' ? 0 : rigFromLocalStorage);
    const [rigName, setRigName] = useState('')

    const componenteLocalStorage = localStorage.getItem('componente') || 'home'
    const [componente, setComponente] = useState(componenteLocalStorage)

    const [activoHover, setActivoHover] = useState(null);

    const [showConfig, setShowConfig] = useState(false);
    const handleClose = () => setShowConfig(false);

    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const [themes, setThemes] = useState({
        light: {
            sidebar: {
                backgroundColor: '#ffffff',
                color: '#607489',
            },
            font: { color: 'black' }
        },
        dark: {
            sidebar: {
                backgroundColor: '#0b2948',
                color: '#8ba1b7',
            },
            font: { color: 'white' }
        },
        custom: {
            sidebar: {
                backgroundColor: colors?.colorBg || '#ffffff',
                color: colors?.colorFont || '#000000',
            },
            font: { color: colors?.colorFont || '#000000' }
        }
    })

    useEffect(() => {

        validarMobil(setMobile, setHorizontal)
        iniciarReloj()
        getRigs()
        getVariables(setVariables)

        const handleBackButton = (event) => { event.preventDefault(); };

        window.addEventListener('popstate', handleBackButton);
        return () => { window.removeEventListener('popstate', handleBackButton) };
    }, [])

    const iniciarReloj = () => {
        const interval = setInterval(() => {
            setReloj(moment().format('LTS'))
        }, 1000);
        return () => clearInterval(interval);
    }

    const handleMouseEnter = (index) => { setActivoHover(index); };
    const handleMouseLeave = () => { setActivoHover(null); };
    const changeTitle = async (tittle) => { document.title = tittle; };

    useEffect(() => {
        const administracion = ['variables', 'users', 'rigs', 'plantilla', 'variablesForm']
        if (administracion.includes(componente) && tipo !== '1') {
            setAdminShow(false)
            setComponente('home')
        }
    }, [tipo, componenteLocalStorage])

    useEffect(() => {

        const newPath = `/${componente}`;
        window.history.pushState({ previousPath: window.location.pathname }, '', newPath);

        localStorage.setItem('componente', componente);

        if (componente !== 'variables' && componente !== 'users') setAdminShow(false)
        if (mobile) setToggled(!toggled)

        switch (componente) {
            case 'home':
                history.replace('/home');
                changeTitle('Home')
                break;
            case 'livedata':
                history.replace('/livedata');
                changeTitle('Live Data')
                break;
            case 'largedata':
                history.replace('/largedata');
                changeTitle('Large Data')
                break;
            case 'livegraphs':
                history.replace('/livegraphs');
                changeTitle('Live Graphs')
                break;
            case 'livegraphs2':
                history.replace('/livegraphs2');
                changeTitle('Live Graphs 2')
                break;
            case 'pits':
                history.replace('/pits');
                changeTitle('Pits')
                break;
            case 'variables':
                history.replace('/variables');
                changeTitle('Vars')
                break;
            case 'variablesForm':
                history.replace('/variablesForm');
                changeTitle('VarsForm')
                break;
            case 'users':
                history.replace('/users');
                changeTitle('Users')
                break;
            case 'myrigs':
                history.replace('/myrigs');
                changeTitle('My Rigs')
                break;
            case 'plantilla':
                history.replace('/plantillas');
                changeTitle('Plantillas')
                break;
            case 'rigs':
                history.replace('/rigs');
                changeTitle('Rigs')
                break;
            default:
                break;
        }
    }, [componente])

    useEffect(() => {
        localStorage.setItem('rig', rig);
        consultarRig()
        getVariables(setVariables)
    }, [rig])

    const consultarRig = async () => {
        if (rigs.length === 0) await getRigs()
        const rigTempo = rigs?.find((ri) => parseInt(ri.nb_tldro_idn) === parseInt(rig))
        if (rigTempo) {
            setRigName(rigTempo.vr_tldro_nom)
            localStorage.setItem('fInicial', rigTempo.dt_adt_fchreg);
        } else {
            const selectElement = document.getElementById('seleccionarRig')
            const selectedIndex = selectElement?.selectedIndex;
            const selectedOption = selectElement?.options[selectedIndex];
            const selectedText = selectedOption?.text;
            setRigName(selectedText);
        }
    }

    useEffect(() => {
        let coloresTemporales = {}
        const themesCopy = { ...themes }
        switch (theme) {
            case 'light':
                coloresTemporales = {
                    colorBg: '#ffffff',
                    colorFont: '#000000',

                    ldColorAlarm: '#ff0000',
                    ldColorBg: '#ffffff',
                    ldColorBorder: '#e6e6e6',
                    ldColorFont: '#000000',
                    ldColorMeasure: '#000000',

                    grColorAlarm: '#ff0000',
                    grColorBg: '#ffffff',
                    grColorBorder: '#e6e6e6',
                    grColorFont: '#000000',
                    grColorMeasure: '#000000',
                    grColor1_1: '#002ee6',
                    grColor1_2: '#000000',
                    grColor2_1: '#002ee6',
                    grColor2_2: '#000000',
                    grColor3_1: '#002ee6',
                    grColor3_2: '#000000',
                    grColor4_1: '#002ee6',
                    grColor4_2: '#000000',
                    grColor5_1: '#002ee6',
                    grColor5_2: '#000000',
                }
                break;
            case 'dark':
                coloresTemporales = {
                    colorBg: '#ffffff',
                    colorFont: '#8ba1b7',

                    ldColorAlarm: '#ff0000',
                    ldColorBg: '#ffffff',
                    ldColorBorder: '#e6e6e6',
                    ldColorFont: '#000000',
                    ldColorMeasure: '#000000',

                    grColorAlarm: '#ff0000',
                    grColorBg: '#ffffff',
                    grColorBorder: '#e6e6e6',
                    grColorFont: '#000000',
                    grColorMeasure: '#000000',
                    grColor1_1: '#002ee6',
                    grColor1_2: '#000000',
                    grColor2_1: '#002ee6',
                    grColor2_2: '#000000',
                    grColor3_1: '#002ee6',
                    grColor3_2: '#000000',
                    grColor4_1: '#002ee6',
                    grColor4_2: '#000000',
                    grColor5_1: '#002ee6',
                    grColor5_2: '#000000',

                    /* ldColorAlarm: '#ff0000',
                    ldColorBg: '#0b2948',
                    ldColorBorder: '#8ba1b7',
                    ldColorFont: '#8ba1b7',
                    ldColorMeasure: '#8ba1b7',

                    grColorAlarm: '#ff0000',
                    grColorBg: '#0b2948',
                    grColorBorder: '#8ba1b7',
                    grColorFont: '#8ba1b7',
                    grColorMeasure: '#8ba1b7',
                    grColor1_1: '#002ee6',
                    grColor1_2: '#000000',
                    grColor2_1: '#002ee6',
                    grColor2_2: '#000000',
                    grColor3_1: '#002ee6',
                    grColor3_2: '#000000',
                    grColor4_1: '#002ee6',
                    grColor4_2: '#000000',
                    grColor5_1: '#002ee6',
                    grColor5_2: '#000000', */
                }
                break;
            case 'custom':
                coloresTemporales = {
                    colorBg: "#050505",
                    colorFont: "#08e740",

                    ldColorAlarm: "#ef0b0b",
                    ldColorBg: "#121212",
                    ldColorBorder: "#9c9c9c",
                    ldColorFont: "#ffffff",
                    ldColorMeasure: "#00ff1e",

                    grColorAlarm: '#ff0000',
                    grColorBg: '#ffffff',
                    grColorBorder: "#12a114",
                    grColorFont: "#2e9004",
                    grColorMeasure: "#1dfa00",
                    grColor1_1: "#07e40b",
                    grColor1_2: "#f308f7",
                    grColor2_1: "#07e40b",
                    grColor2_2: "#f308f7",
                    grColor3_1: "#07e40b",
                    grColor3_2: "#f308f7",
                    grColor4_1: "#07e40b",
                    grColor4_2: "#f308f7",
                    grColor5_1: "#07e40b",
                    grColor5_2: "#f308f7",
                    grColorBg: "#050505",
                }
                const colorsCustom = JSON.parse(localStorage.getItem('colorsCustom')) || {}
                if (Object.keys(colorsCustom).length > 0) coloresTemporales = colorsCustom
                themesCopy.custom.sidebar.backgroundColor = colorsCustom.colorBg || '#ffffff'
                themesCopy.custom.sidebar.color = colorsCustom.colorFont || '#000000'
                themesCopy.custom.font.color = colorsCustom.colorFont || '#000000'

                break;
            default:
                break;
        }
        setColors(coloresTemporales)
        setThemes(themesCopy)

        localStorage.setItem("theme", theme)
    }, [theme])

    useEffect(() => {
        const themesCopy = { ...themes }
        themesCopy.custom.sidebar.backgroundColor = colors.colorBg || '#ffffff'
        themesCopy.custom.sidebar.color = colors.colorFont || '#000000'
        themesCopy.custom.font.color = colors.colorFont || '#000000'
        setThemes(themesCopy)
    }, [colors])

    //-------------------------------------------------------------------

    const getRigs = async () => {
        setIsLoading(true)
        const uid = JSON.parse(localStorage.getItem('token')).id
        try {
            await cargarLogos()
            const data = await getTableByIdBd(uid, 'getrigsusers')
            setRigs(data);
        } catch (error) {
            toast.warning('bd don\'t work');
        } finally {
            setIsLoading(false)
        }
    }

    //-------------------------------------------------------------------

    const cargarLogos = async () => {
        try {
            const url = await obtenerURLImagen('gs://wellcheck-ce3d6.appspot.com/logo.png');
            const urlTw = await obtenerURLImagen('gs://wellcheck-ce3d6.appspot.com/twLogo.png');
            const url2 = await obtenerURLImagen('gs://wellcheck-ce3d6.appspot.com/logoWhite.png')
            const urlTw2 = await obtenerURLImagen('gs://wellcheck-ce3d6.appspot.com/twLogoW.png');
            setLogoTw(urlTw)
            setLogo(url);
            setLogoTwW(urlTw2)
            setLogoW(url2)
        } catch (error) {
            toast.warning("error al consultar logos")
        }
    };

    //-------------------------------------------------------------------

    const menuTw = (icono, activo, compo, titulo) => {
        return (
            <MenuItem
                icon={<i className={icono} />}
                active={componente === activo}
                onClick={() => setComponente(compo)}
                className={'update-item ' + (componente === compo ? "text-info" : "")}
            >
                {titulo}
            </MenuItem>

        )
    }

    //-------------------------------------------------------------------
    return (
        <>
            <ToastContainer position="bottom-right" />

            <Config
                show={showConfig}
                handleClose={handleClose}
                colors={colors}
                setColors={setColors}
                setTheme={setTheme} theme={theme} />

            <div style={{ display: 'flex', minHeight: '100vh' }}>

                <Sidebar
                    collapsed={collapsed}
                    toggled={toggled}
                    rtl={false}
                    breakPoint="lg"
                    rootStyles={{ color: themes[theme].sidebar.color, zIndex: 2 }}
                    backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                    onBackdropClick={() => setToggled(false)}
                    onBreakPoint={(broken) => setBroken(broken)} >
                    <div className={broken ? 'mt-4' : ''}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'hiden',
                            maxHeight: broken ? 'calc(100vh - 48px)' : '100vh'
                        }}>

                        <div style={{ flex: 1 }} className={broken ? 'mt-2' : ''}>

                            {/* Logo Empresa Usuario */}
                            <div className=" text-center continer">

                                {mobile && (
                                    <>
                                        <div className="row d-flex align-items-center">
                                            <div className="col-9 mt-1 ms-3" style={{ fontSize: '10px' }}>
                                                {!collapsed && (
                                                    <span className={"navbar-text "}
                                                        style={{ color: colors.colorFont }}>
                                                        {reloj} </span>
                                                )}
                                                {(componente === 'plantilla' || componente === 'variables' || componente === 'users') &&
                                                    <Help ruta={componente} />
                                                }
                                            </div>
                                            <div className={`${!collapsed ? 'col-2' : 'ms-auto'}`} >
                                                <i onClick={() => setCollapsed(!collapsed)}
                                                    style={{ cursor: 'pointer', fontSize: '12px' }}
                                                    className={"btn border-0 fa-solid text-end " + (collapsed ? 'fa-angle-right ' : 'fa-angle-left ') + (theme === 'dark' ? ' text-white' : '')}
                                                />
                                            </div>
                                        </div>
                                        <hr className='mt-1 mb-0' />
                                    </>
                                )}

                                <img src={theme === 'dark' ? logoTwW : logoTw}
                                    className="img-fluid mx-auto my-3"
                                    style={{ width: mobile ? '150px' : '200px' }}
                                    alt="Logo"
                                />

                                <div>
                                    {!collapsed && (
                                        <select className="form-select form-select-sm mx-1 mb-2"
                                            id='seleccionarRig'
                                            value={rig}
                                            onChange={(e) => setRig(parseInt(e.target.value))}>
                                            <option value={0} disabled>Rig Selection</option>
                                            {rigs.map(r => (
                                                <option key={r.nb_tldro_idn} value={r.nb_tldro_idn}>{r.vr_tldro_nom}</option>
                                            ))}
                                        </select>
                                    )}
                                    {/* User */}
                                    <div className="row align-items-center">
                                        <div className="col col align-self-center">
                                            <p className="text-center pe-2 mb-0"
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModificarClave"
                                                id="modalModificar"
                                                title="Cambiar clave"
                                                style={{
                                                    fontSize: mobile ? '10px' : "12px",
                                                    cursor: "pointer"
                                                }}>
                                                {user.toUpperCase()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-2 mt-2 bg-light hr-menu" />

                            <ProMenu>
                                {menuTw('fa fa-home', 'home', 'home', 'Home')}
                                <hr className="m-0 bg-light hr-menu" />
                                {menuTw('fa fa-braille', 'livedata', 'livedata', 'Live Data')}
                                <hr className="m-0 bg-light hr-menu" />
                                {menuTw('fa fa-chart-line', 'livegraphs', 'livegraphs', 'Graphs')}
                                <hr className="m-0 bg-light hr-menu" />
                                {/* {menuTw('fa fa-water', 'pits', 'pits', 'Pits')}
                                <hr className="m-0 bg-light" /> */}
                                {menuTw('fa fa-helmet-safety', 'myrigs', 'myrigs', 'My Rigs')}
                                <hr className="m-0 bg-light hr-menu" />

                                {/* Administracion---------------------------------------------------------------- */}

                                {tipo === '1' && (
                                    <>
                                        <SubMenu
                                            open={adminShow}
                                            label="Administración"
                                            icon={<i className="fa-solid fa-user-shield"></i>}
                                            className={"update-item " + (adminShow || (componente === "variables" || componente === "users") ? 'text-info' : '')}
                                            onClick={() => setAdminShow(!adminShow)}>

                                            <MenuItem
                                                className={'update-item ' + (componente === "variables" || activoHover === 40 ? "active" : "")}
                                                onClick={() => setComponente('variables')}
                                                style={{ cursor: 'pointer' }}
                                                onMouseEnter={() => handleMouseEnter(40)}
                                                onMouseLeave={handleMouseLeave}>
                                                <span className="fa-solid fa-map-signs me-3 ">
                                                </span>{" "}
                                                Variables
                                            </MenuItem>

                                            <MenuItem
                                                className={'update-item ' + (componente === "rigs" || activoHover === 41 ? "active" : "")}
                                                onClick={() => setComponente('rigs')}
                                                style={{ cursor: 'pointer' }}
                                                onMouseEnter={() => handleMouseEnter(41)}
                                                onMouseLeave={handleMouseLeave}>
                                                <span className="fa-solid fa-oil-well me-3 " />{" "}
                                                Rigs
                                            </MenuItem>

                                            <MenuItem
                                                className={'update-item ' + (componente === "users" || activoHover === 42 ? "active" : "")}
                                                onClick={() => setComponente('users')}
                                                style={{ cursor: 'pointer' }}
                                                onMouseEnter={() => handleMouseEnter(42)}
                                                onMouseLeave={handleMouseLeave}>
                                                <span className="fa-solid fa-users-gear me-3"></span>{" "}
                                                Usuarios
                                            </MenuItem>

                                            {/* <MenuItem
                                            className={'update-item ' + (componente === "modbus" || activoHover === 44 ? "active" : "")}
                                            onClick={() => setComponente('modbus')}
                                            style={{ cursor: 'pointer' }}
                                            onMouseEnter={() => handleMouseEnter(44)}
                                            onMouseLeave={handleMouseLeave}>
                                            <span className="fa-solid fa-network-wired me-3"></span>{" "}
                                            Modbus
                                        </MenuItem> */}

                                            <MenuItem
                                                className={'update-item ' + (componente === "plantilla" || activoHover === 46 ? "active" : "")}
                                                onClick={() => setComponente('plantilla')}
                                                style={{ cursor: 'pointer' }}
                                                onMouseEnter={() => handleMouseEnter(46)}
                                                onMouseLeave={handleMouseLeave}>
                                                <span className="fa-regular fa-object-group me-3"></span>{" "}
                                                Plantillas
                                            </MenuItem>

                                            {!mobile && (
                                                <MenuItem
                                                    className={'update-item ' + (componente === "variablesForm" || activoHover === 41 ? "active" : "")}
                                                    onClick={() => setComponente('variablesForm')}
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseEnter={() => handleMouseEnter(41)}
                                                    onMouseLeave={handleMouseLeave}>
                                                    <span className="fa-solid fa-map-signs me-3 ">
                                                    </span>{" "}
                                                    Formulación
                                                </MenuItem>
                                            )}

                                        </SubMenu>
                                        <hr className="m-0 bg-light hr-menu" />
                                    </>
                                )}

                                <hr className="m-0 bg-light hr-menu" />

                                <LogoutButton theme={theme} />

                            </ProMenu>

                        </div>

                        <img src={theme === 'light' ? logo : logoW}
                            className="img-fluid mx-auto"
                            style={{ width: mobile ? '150px' : '200px' }}
                            alt="Logo" />

                        {!mobile && (
                            <i onClick={() => setCollapsed(!collapsed)}
                                className={"btn border-0 fa-solid text-end " + (collapsed ? 'fa-angle-right ' : 'fa-angle-left ') + (theme === 'dark' ? ' text-white' : '')}
                            />
                        )}
                    </div>
                </Sidebar >

                <main className="w-100" style={{ backgroundColor: colors.colorBg }}>

                    {/* Header */}
                    <nav className="navbar navbar-expand-lg fixed-top p-0 border-bottom"
                        style={{
                            backgroundColor: hexToRgba(themes[theme].sidebar.backgroundColor, 1),
                            marginLeft: broken || mobile ? '' : (!collapsed ? '250px' : '80px'),
                            transition: 'margin-left 0.3s ease'
                        }}>
                        <div className="container-fluid m-0 p-0 ">
                            <div className="row align-items-center w-100">

                                <div className="col-1 me-auto text-start z-3">
                                    {broken
                                        ? <i className={"btn fa-solid fa-bars z-3 "}
                                            style={{ color: colors.colorFont }}
                                            onClick={() => setToggled(!toggled)} />

                                        : <i onClick={() => setCollapsed(!collapsed)}
                                            className={"btn border-0 fa-solid text-end " + (collapsed ? 'fa-angle-right ' : 'fa-angle-left ') + (theme === 'dark' ? 'text-white' : '')}
                                        />
                                    }
                                </div>

                                <div className="col text-center ms-4">
                                    <span className={"navbar-text"}
                                        style={{ fontSize: mobile ? '12px' : '16px', color: colors.colorFont }}>
                                        <div className="ticker-container z-0">
                                            <div className="ticker-text ">
                                                {mobile
                                                    ? `${componente ? componente?.toUpperCase() : 'Home'}: ${rigName?.length > 20 ? rigName?.substring(0, 20) : rigName}...`
                                                    : `${componente ? componente?.toUpperCase() : 'Home'}: ${rigName}`
                                                }
                                            </div>
                                        </div>
                                    </span>
                                </div>

                                <div className={`col-${mobile ? '1' : '3'} ms-auto text-end px-0 z-3`}
                                    style={{ fontSize: mobile ? '12px' : '16px' }}>

                                    {!mobile && <span className={"navbar-text"} style={{ color: colors.colorFont }}> {reloj} </span>}

                                    <i className={"btn border-0 fa-solid fa-gear mb-1"}
                                        style={{ color: colors.colorFont }}
                                        onClick={() => setShowConfig(!showConfig)} />

                                </div>
                            </div>
                        </div>
                    </nav>
                    {/* Header */}

                    <div className={`container-fluid text-center ${mobile ? 'mt-3 pt-4' : 'mt-5 pt-1'} `}>
                        {isLoading
                            ? <LoaderES />
                            : <>
                                {componente === 'home'
                                    && <Home
                                        broken={broken}
                                        collapsed={collapsed}
                                        setRig={setRig}
                                        setComponente={setComponente}
                                        mobile={mobile}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'livedata'
                                    && <LiveData
                                        rig={rig}
                                        mobile={mobile}
                                        variables={variables}
                                        broken={broken}
                                        collapsed={collapsed}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'livegraphs'
                                    && <LiveGraphs
                                        rig={rig}
                                        setRig={setRig}
                                        mobile={mobile}
                                        horizontal={horizontal}
                                        variables={variables}
                                        broken={broken}
                                        collapsed={collapsed}
                                        user={user}
                                        uid={uId}
                                        setCollapsed={setCollapsed}
                                        setToggled={setToggled}
                                        colors={colors}
                                    />
                                }

                                {componente === 'pits'
                                    && <Pits
                                        rig={rig}
                                        mobile={mobile}
                                        broken={broken}
                                        collapsed={collapsed}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'myrigs'
                                    && <MyRigs
                                        broken={broken}
                                        collapsed={collapsed}
                                        setRig={setRig}
                                        setComponente={setComponente}
                                        mobile={mobile}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'variables'
                                    && <Variables
                                        broken={broken}
                                        collapsed={collapsed}
                                        mobile={mobile}
                                        variables={variables}
                                        setVariables={setVariables}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'variablesForm'
                                    && <VariablesForm
                                        rig={rig}
                                        rigs={rigs}
                                        setRig={setRig} mobile={mobile}
                                        variables={variables}
                                        broken={broken}
                                        collapsed={collapsed}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'users'
                                    && <Users
                                        broken={broken}
                                        collapsed={collapsed}
                                        mobile={mobile}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'plantilla'
                                    && <Plantillas
                                        broken={broken}
                                        collapsed={collapsed}
                                        mobile={mobile}
                                        variables={variables}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }

                                {componente === 'rigs'
                                    && <Rigs
                                        broken={broken}
                                        collapsed={collapsed}
                                        setComponente={setComponente}
                                        mobile={mobile}
                                        user={user}
                                        uid={uId}
                                        colors={colors} />
                                }
                            </>
                        }
                    </div>
                </main >

            </div >

        </>
    )
}

export default Menu