import React, { useEffect, useState } from 'react'
import { LoaderES } from '../../LoaderES';
import { Filtrar } from '../../Filtrar';
import { Pagination } from '../../Pagination';
import { getTableBd, ordenar, updateTableBd } from '../../../config/functions';
import { toast } from 'react-toastify';
import { Collapse } from 'react-bootstrap';
import './css/rigs.css'

const Rigs = ({ broken, collapsed, mobile, colors }) => {

    const [taladros, setTaladros] = useState([])
    const [taladrosInactivos, setTaladrosInactivos] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);

    const [registrosPorPagina, setRegistrosPorPagina] = useState(20);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])
    const [open, setOpen] = useState(null);

    const [mostrarInactivos, setMostrarInactivos] = useState(false);

    const getRigs = async () => {
        setIsLoading(true)
        try {
            const data = await getTableBd('gettldrs')
            if (data && data.length >= 1) {
                data.forEach(t => {
                    const [rig, pozo] = t.vr_tldro_nom.split('-')
                    t.rig = rig.trim()
                    t.pozo = pozo.trim()
                    t.active = parseInt(t.active);
                })
                const activos = data.filter(t => t.active === 1)
                const inactivos = data.filter(t => t.active === 0)
                setTaladros(activos)
                setDocumentosFiltrados(activos)
                setTaladrosInactivos(inactivos)
            }
        } catch (error) {
            toast.error('Error al cargar los taladros')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRigs()
    }, [])


    //---------------------------------------------

    useEffect(() => {
        setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    }, [documentosFiltrados, registrosPorPagina])

    useEffect(() => {
        const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
        const fin = inicio + parseInt(registrosPorPagina)
        setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
    }, [paginaActual, totalPaginas])

    //---------------------------------------------

    const handleActiveChange = async (tldro) => {

        const data = {
            nb_tldro_idn: parseInt(tldro.nb_tldro_idn),
            active: tldro.active === 0 ? 1 : 0
        };

        //console.log(data);

        const confirmMessage = (tldro.active === 0)
            ? `¿Desea activar el pozo: ${tldro.pozo} del taladro: ${tldro.rig}?`
            : `¿Desea inactivar el pozo: ${tldro.pozo} del taladro: ${tldro.rig}?`;

        const confirmed = window.confirm(confirmMessage);
        if (confirmed) {
            const response = await updateTableBd('updatetldroactive', data)
            if (response) {
                toast.success(`El pozo ${tldro.pozo} del taladro ${tldro.rig} ha sido ${tldro.active ? 'activado' : 'inactivado'}`);
                getRigs()
            }
        }
    }


    //---------------------------------------------

    const thOrd = (name, col) => {
        return (
            <th scope="col">{name}&nbsp;&nbsp;
                <i className="fa-solid fa-arrow-down-a-z"
                    onClick={() => ordenar(col)}
                    style={{ cursor: "pointer" }}></i>
            </th>
        )
    }

    //---------------------------------------------

    const handleToggleTaladros = () => {
        if (mostrarInactivos) {
          setDocumentosFiltrados(taladros);
          setRegistrosPaginaActual(taladros);
        } else {
          setDocumentosFiltrados(taladrosInactivos);
          setRegistrosPaginaActual(taladrosInactivos);
        }
        setMostrarInactivos(!mostrarInactivos);
      };

    //---------------------------------------------

    return (
        <>
            {(taladros.length === 0 || isLoading) && <LoaderES />}

            <>
                <div className={`row ${mobile ? 'mb-3' : ''}`}>
                    <div className="col-4 col-md-1">
                        <button
                            title={`Mostrar Taladros ${mostrarInactivos ? 'activos' : 'inactivos'}`}
                            className={`btn fa-solid ${mostrarInactivos ? 'fa-eye' : 'fa-eye-slash'}`}
                            style={{ color: colors.colorFont || '#000000', borderColor: colors.colorFont || '#000000' }}
                            onClick={() => handleToggleTaladros()}
                        />
                    </div>
                    <div className="col-8 col-md-11">
                        <Filtrar
                            documentos={taladros}
                            setDocumentosFiltrados={setDocumentosFiltrados}
                            setRegistrosFiltrados={setRegistrosPaginaActual}
                            registrosPorPagina={registrosPorPagina}
                            colors={colors} />
                    </div>
                </div>

                {mobile
                    ? registrosPaginaActual && registrosPaginaActual.map((r, index) => (
                        <div key={r.nb_tldro_idn}>
                            <table className="table table-sm table-hover table-striped mb-0" style={{ color: colors.colorFont || '#000000' }}>
                                <thead style={{ fontSize: '14px' }}>
                                    <tr >
                                        <th style={{ width: '50px', resize: 'none' }}>
                                            <button className="btn  fa-solid fa-chevron-down btn-sm"
                                                onClick={() => setOpen(open === index ? null : index)}
                                                style={{ color: colors.colorFont || '#000000' }}
                                                aria-controls={"collapse-rig-" + index}
                                                aria-expanded={open === index}
                                            />
                                        </th>
                                        <th style={{ width: '150px', resize: 'none' }}>
                                            <div>{`${r.vr_tldro_nom.split('-')[1]}  `} </div>
                                        </th>

                                    </tr>
                                </thead>
                            </table>

                            <Collapse in={open === index}>
                                <div id={"collapse-rig-" + index} className="mt-2">
                                    <div className="card card-body" style={{ backgroundColor: colors.colorBg || '#000000', borderColor: colors.colorFont || '#000000' }}>
                                        <table className="table table-sm" style={{ color: colors.colorFont || '#000000' }}>
                                            <tbody style={{ fontSize: '12px' }}>

                                                <tr >
                                                    <td>Taladro: </td>
                                                    <td>{r.rig}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pozo:</td>
                                                    <td>{r.pozo}</td>
                                                </tr>
                                                <tr>
                                                    <td>Descipción:</td>
                                                    <td>{r.vr_tldro_des === 'TAladro no configurado, creado automáticamente por el sistemaaa' ?
                                                        'Sin Descripción' :
                                                        r.vr_tldro_des}</td>
                                                </tr>

                                                <tr>
                                                    <td>Opciones</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button className='fa-solid fa-pen btn btn-sm btn-outline-info'
                                                            />
                                                            <button className='fa-solid fa-trash-can btn btn-sm btn-outline-danger'
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapse>

                        </div>
                    ))
                    : <div className="table-responsive mt-3">
                        <table className="table table-sm table-hover table-bordered align-middle" id='myTable'
                            style={{ color: colors.colorFont || '#000000' }}>
                            <thead className='text-center'>
                                <tr>
                                    {thOrd('id', 0)}
                                    {thOrd('Taladro', 1)}
                                    {thOrd('Pozo', 2)}
                                    {thOrd('Descripción', 3)}
                                    <th>Activo</th>
                                    <th>Opciones</th>

                                </tr>
                            </thead>
                            <tbody className='text-center' style={{ fontSize: '11px' }}>
                                {registrosPaginaActual.map(tldro => (
                                    <tr key={tldro.nb_tldro_idn} className={`${tldro.active === 0 ? 'table-danger' : ''}`}>
                                        <td>{tldro.nb_tldro_idn}</td>
                                        <td>{tldro.rig}</td>
                                        <td>{tldro.pozo}</td>
                                        <td>{tldro.vr_tldro_des === 'TAladro no configurado, creado automáticamente por el sistemaaa' ?
                                            'Sin Descripción' :
                                            tldro.vr_tldro_des}
                                        </td>
                                        <td className="d-flex justify-content-center align-items-center">
                                            <div className="form-check form-switch">
                                                <input type="checkbox" className="form-check-input form-check-lg"
                                                    checked={tldro.active}
                                                    onChange={() => handleActiveChange(tldro)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                                <button className='fa-solid fa-pen btn btn-sm btn-outline-info'
                                                    onClick={() => toast.info('Función en Desarrollo')}
                                                />
                                                <button className='fa-solid fa-trash-can btn btn-sm btn-outline-danger'
                                                    onClick={() => toast.info('Función en Desarrollo')}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }


            </>

            <Pagination
                paginaActual={paginaActual}
                totalPaginas={totalPaginas}
                setPaginaActual={setPaginaActual}
                registrosPorPagina={registrosPorPagina}
                setRegistrosPorPagina={setRegistrosPorPagina}
                documentosFiltrados={documentosFiltrados}
                broken={broken}
                collapsed={collapsed}
                mobile={mobile}
                colors={colors}
            />
        </>
    )
}

export default Rigs